import * as React from "react"
import gatsbyConfig from "../../gatsby-config"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import OgImg from 'images/image--top1.webp'

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {

  const title = 'WaaF | 404'

  return (
    <main style={pageStyles}>
      <Helmet>
        <html lang={ gatsbyConfig.siteMetadata.lang } />
        <title>{ title }</title>
        <meta name="description" content={ gatsbyConfig.siteMetadata.description } />
        <meta name="keywords" content={ gatsbyConfig.siteMetadata.keywords } />
        <meta name="image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:title" content={ title } />
        <meta property="og:site_name" content={ title } />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ gatsbyConfig.siteMetadata.siteUrl } />
        <meta property="og:image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:description" content={ gatsbyConfig.siteMetadata.description } />
        <meta property="og:locale" content="ja_JP" />
        <link rel="shortcut icon" href="../images/icon.png" type="image/x-icon" />
        <link rel="apple-touch-icon-precomposed" href="../images/icon.png" />
      </Helmet>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
